import { SiteListUrlParams } from '$pages/sitelistpage-react/modules/sitelist-utils';
import { ReactWrapper } from '$pages/common/react-wrapper';
import SitelistWrapper, { ISitelistWrapperProps } from './sitelist-wrapper';

export class SitelistPage extends ReactWrapper<
  ISitelistWrapperProps,
  SiteListUrlParams
> {
  constructor() {
    super(SitelistWrapper, (params) => ({
      params,
    }));
  }
}
