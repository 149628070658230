import React, { FC } from 'react';
import Sitelist from './modules/sitelist/sitelist.react';
import { SiteListUrlParams } from '$pages/sitelistpage-react/modules/sitelist-utils';
import { useIsMobile } from '$lib/hooks/isMobile';
import MobileSitelist from './modules/mobile-sitelist/mobile-sitelist';

export interface ISitelistWrapperProps{
  params?: SiteListUrlParams
} 

const SitelistWrapper: FC<ISitelistWrapperProps> = ({ params }) => {
  const mobile = useIsMobile()

  if (mobile) { 
    return <MobileSitelist params={params} />
  }
  
  return (
    <Sitelist params={params}/>
  );
};

export default SitelistWrapper;